<template>
  <v-container style="height: calc(100vh - 60px); overflow-y: auto" fluid>
    <SimpleTable
      ref="dataTable"
      :headers="headers"
      creatable
      editable
      removable
      item-name="Kíp thi"
      url="/examinations?owner=true"
      class="elevation-1"
    >
      <template v-slot:item-actions="{ item }">
        <v-btn title="Phòng thi" icon @click="goToExam(item)">
          <v-icon> mdi-briefcase </v-icon>
        </v-btn>

        <v-btn
          title="Danh sách sinh viên"
          icon
          @click="importStudentList(item)"
        >
          <v-icon> mdi-account-group </v-icon>
        </v-btn>

        <v-btn title="Xóa dữ liệu bài làm" icon @click="resetExamination(item)">
          <v-icon> mdi-account-remove </v-icon>
        </v-btn>

        <v-btn title="Thống kê" icon @click="fetchExamination(item)">
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        {{ item | examinationStatus(serverTime) }}
      </template>
    </SimpleTable>

    <!-- Delete Dialog -->
    <v-dialog v-model="resetConfirmDialog" max-width="500px">
      <v-card>
        <v-card-title class="h5 font-weight-bold">
          Xóa dữ liệu thí sinh
        </v-card-title>

        <v-card-text>
          <div>Tác vụ này sẽ xóa toàn bộ dữ liệu thi của sinh viên</div>
          <div>và không thể khôi phục lại.</div>

          <v-text-field
            v-model="confirmText"
            label="Nhập chữ delete để đồng ý làm mới"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="resetConfirmDialog = false"> Hủy </v-btn>
          <v-btn
            color="error"
            :disabled="confirmText !== 'delete'"
            :loading="resetLoading"
            @click="performResetExamination"
          >
            XÓA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="informationDialog" max-width="500px">
      <v-card>
        <v-card-title class="font-weight-bold">
          Thống kê kíp thi
        </v-card-title>

        <v-card-text class="pt-0" v-if="information">
          <p class="mb-0">Kíp thi: {{ information.name }}</p>
          <p class="mb-0">
            Số SV :
            {{
              information.examinationSubjects.reduce(
                (total, item) => total + +item.meta.students_count,
                0
              )
            }}
          </p>
          <v-divider class="my-2"></v-divider>
          <p
            class="mb-0"
            v-for="item in information.examinationSubjects"
            :key="item.id"
          >
            {{
              information.subjects.find((s) => s.id === item.subjectId)
                .fullName
            }}: Số đề :{{ item.meta.exams_count }}, số sinh viên:
            {{ item.meta.students_count }}
          </p>
          <v-divider class="my-2"></v-divider>
          <p class="mb-0" v-for="item in information.rooms" :key="item.id">
            Phòng thi: {{ item.name }}, số giám thị :{{ item.meta.users_count }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SimpleTable from "@/components/table/SimpleTable.vue";
import { getExaminationStatus } from "./constants";
import request from "@/utils/request";
import FetchServerTimeMixin from "./FetchServerTimeMixin";

export default {
  mixins: [FetchServerTimeMixin],

  components: {
    SimpleTable,
  },

  filters: {
    examinationStatus(examination, now) {
      return getExaminationStatus(examination, now).text;
    },
  },

  data() {
    return {
      currentExamination: undefined,
      information: null,
      informationDialog: false,
      resetConfirmDialog: false,
      confirmText: "",
      resetLoading: false,

      headers: [
        {
          text: "Tên",
          value: "name",
          rules: "required",
        },
        {
          text: "Các học phần",
          value: "subjects",
          dataType: "has-many",
          dataText: "fullName",
          dataValue: "subjects",
          dataUrl: "/subjects?owner=true",
          sortable: false,
        },
        {
          text: "Loại",
          value: "type",
          dataType: "choice",
          rules: "required",
          data: [
            { text: "Giữa kỳ", value: "M" },
            { text: "Cuối kỳ", value: "F" },
          ],
        },
        {
          text: "T.gian vào phòng thi",
          value: "startTime",
          dataType: "datetime",
        },
        {
          text: "T.gian bắt đầu làm bài",
          value: "startExamTime",
          dataType: "datetime",
        },
        {
          text: "T.gian kết thúc",
          value: "endTime",
          dataType: "datetime",
        },
        {
          text: "Kỳ học",
          value: "termId",
          dataType: "choice",
          rules: "required",
          data: [
            { text: "20202", value: "20202" },
            { text: "20203", value: "20203" },
            { text: "20211", value: "20211" },
            { text: "20212", value: "20212" },
            { text: "20213", value: "20213" },
          ],
        },

        {
          text: "Sinh viên trong Danh sách",
          value: "checkInStudents",
          dataType: "boolean",
          sortable: false,
        },

        {
          text: "Chấm điểm luôn",
          value: "immediatelyScoring",
          dataType: "boolean",
          sortable: false,
        },

        {
          text: "Trạng thái",
          value: "status",
          readOnly: true,
          sortable: false,
        },
      ],
    };
  },

  methods: {
    importStudentList(examination) {
      this.$router.push({
        name: "Sinh viên Kíp thi",
        params: { id: examination.id },
      });
    },

    goToExam(examination) {
      this.$router.push({
        name: "Điều khiển Phòng thi",
        params: { id: examination.id },
      });
    },

    resetExamination(examination) {
      this.currentExamination = examination;
      this.resetConfirmDialog = true;
    },

    async performResetExamination() {
      if (!this.currentExamination) return;

      try {
        this.resetLoading = true;

        await request({
          method: "POST",
          url: `/examinations/${this.currentExamination.id}/reset`,
        });

        this.$snackbar("Làm mới kíp thi thành công!", "success");

        this.$refs.dataTable.fetchData();

        this.resetConfirmDialog = false;
        this.confirmText = undefined;
      } catch (error) {
        console.error(error);
        this.$snackbar(error.message, "error");
      } finally {
        this.resetLoading = false;
      }
    },
    async fetchExamination(item) {
      try {
        const res = await request({
          url: "/examinations/" + item.id + "?information=true",
        });

        this.information = res.data;
        this.informationDialog = true;
      } catch (error) {
        console.error(error);
        this.$snackbar(error.message, "error");
      }
    },
  },
};
</script>
